import {
    Airway,
    Appearance,
    AxType,
    Breathing,
    CapRefill,
    CaseAttendantRole,
    CasePriority,
    Circulation,
    EventInterestStatus,
    EventScheduleStatus,
    ExtremityTemp,
    GcsEyeResponse,
    GcsMotorResponse,
    GcsVerbalResponse,
    MhAffect,
    MhAppearance,
    MhBehaviour,
    MhDelusions,
    MhEyes,
    MhHallucinations,
    MhHomicidality,
    MhMemory,
    MhMood,
    MhMotorActivity,
    MhSpeech,
    MhSuicidality,
    Movement,
    PeripheralPulse,
    PulseLocation,
    PulseRegularity,
    Pupils,
    QualificationDocumentExpiryStatus,
    QualificationDocumentStatus,
    RespiratoryRate,
    RespiratoryRegularity,
    Response,
    Rhythm,
    RxAirwayType,
    RxBreathingVentilationType,
    RxCannulaAttempts,
    RxCannulaSite,
    RxCannulaSize,
    RxConsultMethod,
    RxConsultType,
    RxCprOperation,
    RxCprType,
    RxDefibrillationPads,
    RxDefibrillationType,
    RxEffectiveness,
    RxFirstAidType,
    RxMajorTraumaType,
    RxMedicationRoute,
    RxOxygenType,
    RxType,
    SendForHelp,
    Sensation,
    SkinColour,
    Speech,
    SpinalLocation,
    TextTemplateKind,
    WithdrawRequestStatus,
} from '~/generated/types';

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    messages: {
        en: {
            textTemplate: {
                type: {
                    [TextTemplateKind.EventDuties]: 'Event Duties',
                    [TextTemplateKind.EventEquipmentInstructions]: 'Event Equipment Instructions',
                    [TextTemplateKind.EventStaffBriefing]: 'Event Staff Briefing',
                },
            },
            shiftInterest: {
                interestStatus: {
                    [EventInterestStatus.Interest]: 'Interest ({num})',
                    [EventInterestStatus.NoInterest]: 'No Interest',
                },
                scheduleStatus: {
                    [EventScheduleStatus.FullyScheduled]: 'Fully Scheduled ({num})',
                    [EventScheduleStatus.NotScheduled]: 'Not Scheduled ({num})',
                    [EventScheduleStatus.PartiallyScheduled]: 'Partially Scheduled ({num})',
                },
            },
            withdrawRequest: {
                status: {
                    [WithdrawRequestStatus.Pending]: 'Withdraw Pending',
                    [WithdrawRequestStatus.Accepted]: 'Withdrawn',
                    [WithdrawRequestStatus.Rejected]: 'Withdraw Rejected',
                },
            },
            qualificationDocument: {
                status: {
                    [QualificationDocumentStatus.Pending]: 'Pending Approval',
                    [QualificationDocumentStatus.Approved]: 'Approved',
                    [QualificationDocumentStatus.Draft]: 'Draft',
                },
                expiryStatus: {
                    [QualificationDocumentExpiryStatus.Current]: 'Current',
                    [QualificationDocumentExpiryStatus.Expired]: 'Expired',
                    [QualificationDocumentExpiryStatus.ExpiresSoon]: 'Expires Soon',
                },
            },
            caseCategory: {
                priority: {
                    [CasePriority.QuickCase]: 'Quick Case',
                    [CasePriority.Low]: 'Low',
                    [CasePriority.Medium]: 'Medium',
                    [CasePriority.High]: 'High',
                },
                priorityText: {
                    [CasePriority.QuickCase]: 'Quick Case',
                    [CasePriority.Low]: 'Low Acuity',
                    [CasePriority.Medium]: 'Medium Acuity',
                    [CasePriority.High]: 'High Acuity',
                },
            },
            cases: {
                required: 'Please ensure wherever possible that you gather all required patient details.',
                attendantRole: {
                    [CaseAttendantRole.Administration]: 'Administration',
                    [CaseAttendantRole.Assistant]: 'Assistant',
                    [CaseAttendantRole.ClinicalSupport]: 'Clinical Support',
                    [CaseAttendantRole.CoAttendant]: 'Co-Attendant',
                    [CaseAttendantRole.Commander]: 'Commander',
                    [CaseAttendantRole.Observer]: 'Observer',
                    [CaseAttendantRole.Other]: 'Other',
                    [CaseAttendantRole.Supervisor]: 'Supervisor',
                    [CaseAttendantRole.Transport]: 'Transport',
                    [CaseAttendantRole.Triage]: 'Triage',
                },
                axType: {
                    [AxType.Primary]: 'Primary Ax',
                    [AxType.Vitals]: 'Vitals Ax',
                    [AxType.Respiratory]: 'Respiratory Ax',
                    [AxType.Neurovascular]: 'Neurovascular Ax',
                    [AxType.Gcs]: 'GCS Ax',
                    [AxType.Ecg]: 'ECG Ax',
                    [AxType.Rosier]: 'ROSIER Ax',
                    [AxType.Pain]: 'Pain Ax',
                    [AxType.Spinal]: 'Spinal Ax',
                    [AxType.Mental]: 'Mental Ax',
                    [AxType.Other]: 'Other Ax',
                },
                rxType: {
                    [RxType.RestAndReassurance]: 'R&R',
                    [RxType.Airway]: 'Airway',
                    [RxType.BreathingVentilation]: 'Breathing/Ventilation',
                    [RxType.Defibrillation]: 'Defibrillation',
                    [RxType.Consult]: 'Consult',
                    [RxType.Cpr]: 'CPR',
                    [RxType.FirstAid]: 'First Aid',
                    [RxType.IvAccess]: 'IV Access',
                    [RxType.MajorTrauma]: 'Major Trauma',
                    [RxType.Medications]: 'Medications',
                    [RxType.Other]: 'Other',
                    [RxType.Oxygen]: 'Oxygen',
                },
                yesNo: {
                    YES: 'Yes',
                    NO: 'No',
                },
                response: {
                    [Response.Alert]: 'Alert',
                    [Response.Pain]: 'Pain',
                    [Response.Unresponsive]: 'Unresponsive',
                    [Response.Voice]: 'Voice',
                },
                sendForHelp: {
                    [SendForHelp.AmboBystander]: 'Ambulance (called by bystander)',
                    [SendForHelp.AmboControl]: 'Ambulance (called by control)',
                    [SendForHelp.AmboMedic]: 'Ambulance (called by medic)',
                    [SendForHelp.Fire]: 'Fire',
                    [SendForHelp.NotApplicable]: 'Not Applicable',
                    [SendForHelp.Police]: 'Police',
                },
                airway: {
                    [Airway.Patent]: 'Patent',
                    [Airway.Obstructed]: 'Obstructed',
                    [Airway.NonEffective]: 'Non Effective',
                },
                breathing: {
                    [Breathing.Spontaneous]: 'Spontaneous',
                    [Breathing.Abnormal]: 'Abnormal',
                    [Breathing.NotBreathing]: 'Not Breathing',
                },
                circulation: {
                    [Circulation.StrongPulse]: 'Strong Pulse',
                    [Circulation.AbnormalPulse]: 'Abnormal Pulse',
                    [Circulation.NoPulse]: 'No Pulse',
                },
                appearance: {
                    [Appearance.Calm]: 'Calm, Quiet',
                    [Appearance.Anxious]: 'Anxious',
                    [Appearance.Distressed]: 'Distressed',
                    [Appearance.Exhausted]: 'Exhausted',
                },
                respiratoryRate: {
                    [RespiratoryRate.LowRisk]: 'Low Risk (10-24)',
                    [RespiratoryRate.MediumRisk]: 'Medium Risk (25-29 / 5-9)',
                    [RespiratoryRate.HighRisk]: 'High Risk (>30 / <5)',
                },
                rhythm: {
                    [Rhythm.Regular]: 'Regular',
                    [Rhythm.Irregular]: 'Irregular',
                    [Rhythm.LongInsp]: 'Long Inspiration',
                    [Rhythm.LongExp]: 'Long Expiration',
                },
                skinColour: {
                    [SkinColour.Pink]: 'Pink',
                    [SkinColour.Pale]: 'Pale',
                    [SkinColour.Cyanosis]: 'Cyanotic',
                },
                pulseRegularity: {
                    [PulseRegularity.Regular]: 'Normal',
                    [PulseRegularity.Irregular]: 'Irregular',
                },
                pulseLocation: {
                    [PulseLocation.Brachial]: 'Brachial',
                    [PulseLocation.DorsalisPedis]: 'Dorsalis Pedis',
                    [PulseLocation.PosteriorTibial]: 'Posterior Tibial',
                    [PulseLocation.Carotid]: 'Carotid',
                    [PulseLocation.Radial]: 'Radial',
                    [PulseLocation.Femoral]: 'Femoral',
                },
                respiratoryRegularity: {
                    [RespiratoryRegularity.Normal]: 'Normal',
                    [RespiratoryRegularity.Shallow]: 'Shallow',
                    [RespiratoryRegularity.Irregular]: 'Irregular',
                },
                gcsEyeResponse: {
                    [GcsEyeResponse.Spontaneous]: '[4] Eyes open spontaneously',
                    [GcsEyeResponse.Sound]: '[3] Eyes open to verbal command, speech or shout',
                    [GcsEyeResponse.Pain]: '[2] Eyes open to pain',
                    [GcsEyeResponse.None]: '[1] No eye opening',
                },
                gcsVerbalResponse: {
                    [GcsVerbalResponse.Oriented]: '[5] Oriented',
                    [GcsVerbalResponse.Confused]: '[4] Confused conversation, but able to answer questions',
                    [GcsVerbalResponse.Inappropriate]: '[3] Inappropriate responses, words discernible',
                    [GcsVerbalResponse.Incomprehensible]: '[2] Incomprehensible sounds or speech',
                    [GcsVerbalResponse.None]: '[1] No verbal response',
                },
                gcsMotorResponse: {
                    [GcsMotorResponse.ObeysCommands]: '[6] Obeys commands for movement',
                    [GcsMotorResponse.LocalisesPain]: '[5] Purposeful movement to painful stimulus',
                    [GcsMotorResponse.WithdrawsPain]: '[4] Withdraws from painful stimulus',
                    [GcsMotorResponse.AbnormalFlexion]: '[3] Abnormal flexion, decorticate posture',
                    [GcsMotorResponse.Extension]: '[2] Extensor response, decerebrate posture',
                    [GcsMotorResponse.None]: '[1] No motor response',
                },
                speech: {
                    [Speech.FullSentences]: 'Full Sentences',
                    [Speech.ShortSentences]: 'Short Sentences',
                    [Speech.OneWordPhrase]: 'One word/phrase',
                    [Speech.WordsNone]: 'No Words',
                },
                peripheralPulse: {
                    [PeripheralPulse.Strong]: 'Strong',
                    [PeripheralPulse.Weak]: 'Weak',
                    [PeripheralPulse.Absent]: 'Absent',
                },
                extremityTemp: {
                    [ExtremityTemp.Warm]: 'Warm',
                    [ExtremityTemp.Hot]: 'Hot',
                    [ExtremityTemp.Cold]: 'Cold',
                },
                sensation: {
                    [Sensation.Normal]: 'Normal',
                    [Sensation.Paraesthesia]: 'Paraesthesia',
                    [Sensation.Absent]: 'Absent',
                },
                capRefill: {
                    [CapRefill.Normal]: 'Normal (<2s)',
                    [CapRefill.Delayed]: 'Delayed',
                    [CapRefill.Absent]: 'Absent',
                },
                movement: {
                    [Movement.Normal]: 'Normal',
                    [Movement.Abnormal]: 'Abnormal',
                    [Movement.Painful]: 'Painful',
                },
                spinalLocation: {
                    [SpinalLocation.CervicalMidline]: 'Cervical Midline',
                    [SpinalLocation.CervicalSoftTissue]: 'Cervical Soft Tissue',
                    [SpinalLocation.ThoracicMidline]: 'Thoracic Midline',
                    [SpinalLocation.ThoracicSoftTissue]: 'Thoracic Soft Tissue',
                    [SpinalLocation.LumbarMidline]: 'Lumbar Midline',
                    [SpinalLocation.LumbarSoftTissue]: 'Lumbar Soft Tissue',
                },
                pupils: {
                    [Pupils.Pearl]: 'PEARL',
                    [Pupils.Unequal]: 'Unequal',
                    [Pupils.Sluggish]: 'Sluggish',
                    [Pupils.NonReactive]: 'Non Reactive',
                },
                mhBehaviour: {
                    [MhBehaviour.Aggressive]: 'Aggressive',
                    [MhBehaviour.Agitated]: 'Agitated',
                    [MhBehaviour.Cooperative]: 'Cooperative',
                    [MhBehaviour.Guarded]: 'Guarded',
                    [MhBehaviour.Hyperactive]: 'Hyperactive',
                    [MhBehaviour.Nonsensical]: 'Nonsensical',
                    [MhBehaviour.Paranoid]: 'Paranoid',
                    [MhBehaviour.Stereotyped]: 'Stereotyped',
                    [MhBehaviour.Withdrawn]: 'Withdrawn',
                },
                mhAppearance: {
                    [MhAppearance.Bizarre]: 'Bizarre',
                    [MhAppearance.Disheveled]: 'Disheveled',
                    [MhAppearance.Neat]: 'Neat',
                },
                mhSpeech: {
                    [MhSpeech.Normal]: 'Normal',
                    [MhSpeech.Impoverished]: 'Impoverished',
                    [MhSpeech.Pressured]: 'Pressured',
                    [MhSpeech.Tangential]: 'Tangential',
                },
                mhEyes: {
                    [MhEyes.Normal]: 'Normal',
                    [MhEyes.Avoidant]: 'Normal',
                    [MhEyes.Intense]: 'Intense',
                },
                mhMotorActivity: {
                    [MhMotorActivity.Normal]: 'Normal',
                    [MhMotorActivity.Slowed]: 'Slowed',
                    [MhMotorActivity.Restless]: 'Restless',
                    [MhMotorActivity.Tics]: 'Tics',
                },
                mhAffect: {
                    [MhAffect.Full]: 'Full',
                    [MhAffect.Constricted]: 'Constricted',
                    [MhAffect.Flat]: 'Flat',
                    [MhAffect.Labile]: 'Labile',
                },
                mhMood: {
                    [MhMood.Angry]: 'Angry',
                    [MhMood.Anxious]: 'Anxious',
                    [MhMood.Apathetic]: 'Apathetic',
                    [MhMood.Depressed]: 'Depressed',
                    [MhMood.Dysphoric]: 'Dysphoric',
                    [MhMood.Elevated]: 'Elevated',
                    [MhMood.Euphoric]: 'Euphoric',
                    [MhMood.Euthymic]: 'Euthymic',
                    [MhMood.Irritable]: 'Irritable',
                },
                mhMemory: {
                    [MhMemory.Normal]: 'Normal',
                    [MhMemory.Impaired]: 'Impaired',
                    [MhMemory.Hypermnesia]: 'Hypermnesia',
                    [MhMemory.Amnesia]: 'Amnesia',
                },
                mhHallucinations: {
                    [MhHallucinations.None]: 'None',
                    [MhHallucinations.Auditory]: 'Auditory',
                    [MhHallucinations.Gustatory]: 'Gustatory',
                    [MhHallucinations.Olfactory]: 'Olfactory',
                    [MhHallucinations.Tactile]: 'Tactile',
                    [MhHallucinations.Visual]: 'Visual',
                },
                mhDelusions: {
                    [MhDelusions.None]: 'None',
                    [MhDelusions.Grandiose]: 'Grandiose',
                    [MhDelusions.Jealous]: 'Jealous',
                    [MhDelusions.Paranoid]: 'Paranoid',
                    [MhDelusions.Persecutory]: 'Persecutory',
                    [MhDelusions.Religious]: 'Religious',
                    [MhDelusions.Somatic]: 'Somatic',
                },
                mhSuicidality: {
                    [MhSuicidality.None]: 'None',
                    [MhSuicidality.Ideation]: 'Ideation',
                    [MhSuicidality.Intent]: 'Intent',
                    [MhSuicidality.Plan]: 'Plan',
                    [MhSuicidality.PlanIntent]: 'Plan with Intent',
                    [MhSuicidality.Attempt]: 'Attempt',
                },
                mhHomicidality: {
                    [MhHomicidality.None]: 'None',
                    [MhHomicidality.Agression]: 'Aggression',
                    [MhHomicidality.Intent]: 'Intent',
                    [MhHomicidality.Plan]: 'Plan',
                    [MhHomicidality.PlanIntent]: 'Plan with Intent',
                },

                airwayType: {
                    [RxAirwayType.DoubleAirwayManoeuvre]: 'Double Airway Manoeuvre',
                    [RxAirwayType.TripleAirwayManoeuvre]: 'Triple Airway Manoeuvre',
                    [RxAirwayType.Suction]: 'Suction',
                    [RxAirwayType.Laryngoscopy]: 'Laryngoscopy with Magil Forceps',
                    [RxAirwayType.OropharyngealAirway]: 'Oropharyngeal Airway',
                    [RxAirwayType.NasopharyngealAirway]: 'Nasopharyngeal Airway',
                    [RxAirwayType.SupraglotticAirway]: 'Supraglottic Airway',
                    [RxAirwayType.EndotrachealTube]: 'Endotracheal Tube',
                    [RxAirwayType.Cricothyroidotomy]: 'Cricothyroidotomy',
                },
                breathingVentilationType: {
                    [RxBreathingVentilationType.MouthToMask]: 'Mouth to Mask',
                    [RxBreathingVentilationType.BagValveMask]: 'Bag Valve Mask',
                    [RxBreathingVentilationType.MechanicalVentilation]: 'Mechanical Ventilation',
                    [RxBreathingVentilationType.NeedleThoracocentesis]: 'Needle Thoracocentesis',
                },
                effectiveness: {
                    [RxEffectiveness.Effective]: 'Effective',
                    [RxEffectiveness.PartiallyEffective]: 'Partially Effective',
                    [RxEffectiveness.Ineffective]: 'Ineffective',
                    [RxEffectiveness.UnableToAscertain]: 'Unable to Ascertain',
                },
                cprType: {
                    [RxCprType.HandsOnly]: 'Hands Only',
                    [RxCprType.Cpr_30_2]: '30:2',
                },
                cprOperation: {
                    [RxCprOperation.SingleOperator]: 'Single Operator',
                    [RxCprOperation.MultipleOperator]: 'Multiple Operators',
                },
                defibrillationType: {
                    [RxDefibrillationType.Manual]: 'Manual',
                    [RxDefibrillationType.SemiAutomatic]: 'Semi-Automatic',
                },
                defibrillationPads: {
                    [RxDefibrillationPads.Adult]: 'Adult',
                    [RxDefibrillationPads.Paediatric]: 'Paediatric',
                },
                firstAidType: {
                    [RxFirstAidType.AnatomicalSplinting]: 'Anatomical Splinting',
                    [RxFirstAidType.AntisepticCream]: 'Antiseptic Cream',
                    [RxFirstAidType.ArmSling]: 'Arm Sling',
                    [RxFirstAidType.BurnDressingGel]: 'Burn Dressing/Gel',
                    [RxFirstAidType.CleanedWithSalineGauze]: 'Cleaned with Saline & Gauze',
                    [RxFirstAidType.CompressionBandage]: 'Compression Bandage',
                    [RxFirstAidType.DressedWithAdhesiveDressing]: 'Dressed with Adhesive Dressing',
                    [RxFirstAidType.EyeIrrigation]: 'Eye Irrigation',
                    [RxFirstAidType.EyePad]: 'Eye Pad',
                    [RxFirstAidType.FormableSplint]: 'Formable Splint',
                    [RxFirstAidType.HeatPack]: 'Heat Pack',
                    [RxFirstAidType.IcePack]: 'Ice Pack',
                    [RxFirstAidType.PressureImmobilisationTechnique]: 'Pressure Immobilisation Technique',
                    [RxFirstAidType.SplinterProbe]: 'Splinter Probe',
                    [RxFirstAidType.ThermalBlanket]: 'Thermal Blanket',
                    [RxFirstAidType.WoundClosureStrips]: 'Wound Closure Strips',
                    [RxFirstAidType.WoundDressingWithRollerBandage]: 'Wound Dressing with Roller Bandage',
                    [RxFirstAidType.WoundIrrigation]: 'Wound Irrigation',
                    [RxFirstAidType.Other]: 'Other',
                },
                majorTraumaType: {
                    [RxMajorTraumaType.ArterialTourniquet]: 'Arterial Tourniquet',
                    [RxMajorTraumaType.HaemostaticDressing]: 'Haemostatic Dressing',
                    [RxMajorTraumaType.ChestSeal]: 'Chest Seal',
                    [RxMajorTraumaType.PelvicBinder]: 'Pelvic Binder',
                    [RxMajorTraumaType.TractionSplint]: 'Traction Splint',
                    [RxMajorTraumaType.CervicalCollar]: 'Cervical Collar',
                },
                oxygenType: {
                    [RxOxygenType.NasalCannula]: 'Nasal Cannula',
                    [RxOxygenType.NonRebreatherMask]: 'Non-Rebreather Mask',
                    [RxOxygenType.NebuilserMask]: 'Nebuliser Mask',
                },
                cannulaSize: {
                    [RxCannulaSize.G14]: '14G',
                    [RxCannulaSize.G16]: '16G',
                    [RxCannulaSize.G18]: '18G',
                    [RxCannulaSize.G20]: '20G',
                    [RxCannulaSize.G22]: '22G',
                    [RxCannulaSize.G24]: '24G',
                },
                cannulaSite: {
                    [RxCannulaSite.RDorsumHand]: 'R) Dorsum Hand',
                    [RxCannulaSite.RWrist]: 'R) Wrist',
                    [RxCannulaSite.RForearm]: 'R) Forearm',
                    [RxCannulaSite.RAntecubitalFossa]: 'R) Antecubital Fossa',
                    [RxCannulaSite.RFoot]: 'R) Foot',
                    [RxCannulaSite.LDorsumHand]: 'L) Dorsum Hand',
                    [RxCannulaSite.LWrist]: 'L) Wrist',
                    [RxCannulaSite.LForearm]: 'L) Forearm',
                    [RxCannulaSite.LAntecubitalFossa]: 'L) Antecubital Fossa',
                    [RxCannulaSite.LFoot]: 'L) Foot',
                },
                cannulaAttempts: {
                    [RxCannulaAttempts.One]: '1 attempt',
                    [RxCannulaAttempts.Two]: '2 attempts',
                },
                consultType: {
                    [RxConsultType.InternalStaff]: 'Internal Staff',
                    [RxConsultType.ExternalHcp]: 'External HCP',
                },
                consultMethod: {
                    [RxConsultMethod.InPerson]: 'In Person',
                    [RxConsultMethod.Radio]: 'Radio',
                    [RxConsultMethod.Phone]: 'Phone',
                },
                medicationRoute: {
                    [RxMedicationRoute.Extraocular]: 'Extraocular',
                    [RxMedicationRoute.Inhalation]: 'Inhalation',
                    [RxMedicationRoute.Intramuscular]: 'Intramuscular',
                    [RxMedicationRoute.Intranasal]: 'Intranasal',
                    [RxMedicationRoute.Intraosseous]: 'Intraosseous',
                    [RxMedicationRoute.Intravenous]: 'Intravenous',
                    [RxMedicationRoute.Oral]: 'Oral',
                    [RxMedicationRoute.Subcutaneous]: 'Subcutaneous',
                    [RxMedicationRoute.Sublingual]: 'Sublingual',
                    [RxMedicationRoute.Topical]: 'Topical',
                    [RxMedicationRoute.Transdermal]: 'Transdermal',
                },
            },
        },
    },
}));
